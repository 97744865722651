export const action = {
    // DEPOSIT
    DEPOSIT: 'payment/deposit',
    DEPOSIT_IN_PROGRESS: 'payment/depositInProgress',
    POLL_DEPOSIT: 'payment/pollDeposit',
    FETCH_PAYMENT_INVITATION_LINK: 'payment/fetchPaymentInvitationLink',
    FETCH_PROVIDERS_OPTIONS: 'payment/fetchProvidersOptions',
    FETCH_PROVIDER_DETAILS: 'payment/fetchProviderDetails',
    RESET_DEPOSIT: 'payment/resetDeposit',
    FETCH_DEPOSIT_TELCO_FEE: 'payment/fetchDepositTelcoFee',
    // PAYOUT
    WITHDRAW: 'payment/withdraw',
    CANCEL_PAYOUT: 'payment/cancelPayout',
    FETCH_PAYOUT_PROVIDERS: 'payment/fetchPayoutProviders',
    FETCH_PENDING_PAYOUTS: 'payment/fetchPendingPayouts',
    RESET_PAYOUT_STATUS: 'payment/resetPayoutStatus',
    RESET_PAYOUT_ERROR: 'payment/resetPayoutError',
    FETCH_PAYOUT_TAX_AMOUNT: 'payment/fetchPayoutTaxAmount',
};

export const mutation = {
    // DEPOSIT
    SET_DEPOSIT_DETAILS: 'payment/setDepositDetails',
    SET_DEPOSIT_OPTIONS: 'payment/setDepositOptions',
    SET_DEPOSIT_ERROR: 'payment/setDepositError',
    SET_DEPOSIT_INITIALIZED: 'payment/setDepositInitialized',
    SET_POLL_IN_PROGRESS: 'payment/setPollingInProgress',
    RESERVE_DEPOSIT_ID: 'payment/reserveDepositId',
    SET_PROVIDERS: 'payment/setProviders',
    SET_PROVIDERS_OPTIONS: 'payment/setProvidersOptions',
    SET_PROVIDER_DETAILS: 'payment/setProviderDetails',
    RESET_DEPOSIT: 'payment/resetDeposit',
    SET_DEPOSIT_TELCO_FEE: 'payment/setDepositTelcoFee',
    RESET_DEPOSIT_TELCO_FEE: 'payment/resetDepositTelcoFee',
    // PAYOUT
    SET_PAYOUT_PROVIDERS: 'payment/setPayoutProviders',
    SET_PAYOUT_STATUS: 'payment/setPayoutStatus',
    RESET_PAYOUT_ERROR: 'payment/resetPayoutError',
    RESET_PAYOUT_STATUS: 'payment/resetPayoutStatus',
    SET_EXPERIMENTAL_PAYOUTS: 'payment/setExperimentalPayouts',
    SET_PAYOUT_ERROR: 'payment/setPayoutError',
    SET_PENDING_PAYOUTS: 'payment/setPendingPayouts',
    SET_FIRST_DEPOSIT_DATA: 'payment/setFirstDepositData',
    SET_SELECTED_PROVIDER: 'payment/setSelectedProvider',
    SET_DEPOSIT_COMPONENT_IS_VISIBLE: 'payment/setDepositComponentIsVisible',
    SET_PAYOUT_TAX_AMOUNT: 'payment/setPayoutTaxAmount',
    RESET_PAYOUT_TAX_AMOUNT: 'payment/resetPayoutTaxAmount',
};

export const getter = {
    // DEPOSIT
    GET_FIRST_DEPOSIT_DATA: 'payment/getFirstDepositData',
    GET_PROVIDERS_OPTIONS: 'payment/getProvidersOptions',
    GET_PROVIDERS_LIST: 'payment/getProvidersList',
    GET_PROVIDER_DETAILS: 'payment/getProviderDetails',
    GET_DEPOSIT_INIT: 'payment/getDepositInit',
    GET_DEPOSIT_OPTIONS: 'payment/getDepositOptions',
    GET_POLL_IN_PROGRESS: 'payment/getPollInProgress',
    GET_RESERVED_DEPOSIT: 'payment/getReservedDeposit',
    GET_DEPOSIT_DETAILS: 'payment/getDepositDetails',
    GET_DEPOSIT_ERROR: 'payment/getDepositError',
    IS_DEPOSIT_TELCO_FEE_ENABLED: 'payment/isDepositTelcoFeeEnabled',
    IS_PROVIDER_UNAVAILABLE: 'payment/isProviderUnavailable',
    // PAYOUT
    GET_PAYOUT_PROVIDERS: 'payment/getPayoutProviders',
    GET_PAYOUT_STATUS: 'payment/getPayoutStatus',
    GET_PENDING_PAYOUTS: 'payment/getPendingPayouts',
    GET_PAYOUT_ERROR: 'payment/getPayoutError',
    GET_SELECTED_PROVIDER: 'payment/getSelectedProvider',
    GET_IS_DEPOSIT_COMPONENT_VISIBLE: 'payment/getIsDepositComponentVisible',
    GET_OPTIONS_UUID: 'payment/getOptionsUUID',
    SHOW_LINK: 'payment/showLink',
    IS_WITHDRAW_WHT_DISABLED: 'payment/isWithdrawWhtDisabled',
    GET_PAYMENT_PROVIDER_MIGRATION_DATA: 'payment/getPaymentProviderMigrationData',
};
