<template>
    <div v-if="gamesCount > 0" class="game-counter" @click="openCasinoGames()">
        <span class="pointer">
            <span>{{ $t('ui.casinoLobby.moreGames') }}</span>
            <span class="bold">{{ gamesCount }}</span>
            <SvgIcon icon-id="arrow_right" class="icon-size-very-small" vertical-align="baseline" />
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { routeName } from '@/router/const-name';
import { getter as casinoGetter, action as casinoAction } from '@/store/modules/casino/const';
import { CasinoFilter, CasinoStaticCategories } from '@/modules/casino/utils/CasinoCollection-const';
import { getter as coreGetter } from '@/modules/core/store/const';
export default {
    name: 'CasinoShowAllGames',
    computed: {
        ...mapGetters({
            games: casinoGetter.GET_GAMES,
            isLoading: coreGetter.IS_LOADING,
        }),
        gamesCount() {
            return (this.games.data || []).length;
        },
    },
    mounted() {
        if (!this.isLoading(casinoAction.GET_CASINO_GAMES_NEW)) {
            this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        }
    },
    methods: {
        openCasinoGames() {
            this.$router.push({ name: routeName.CASINO, query: { [CasinoFilter.CATEGORY]: CasinoStaticCategories.ALL } });
        },
    },
};
</script>
<style scoped lang="scss">
.game-counter {
    color: $betpawa-black;
    display: block;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    padding: 12px;
    text-align: center;

    .bold {
        padding: 0 2px 0 10px;
    }

    > span {
        border-bottom: 1px solid $betpawa-black;
        padding-bottom: 1px;
    }
}
</style>
