<template>
    <div v-sniffer="{ handler: onSnifferLoadMore, distance: 150 }" class="casino-lobby no-margins">
        <ErrorPage
            v-if="hasGameLoadError"
            :title="$t('ui.errorPage.tryAgain')"
            :section-list="[$t('ui.casinoLobby.connectionIssue')]"
            icon="icon-reload"
            :button="{ text: $t('ui.errorPage.reloadPage'), emit: true }"
            @button:click="errorButtonClick()"
        />
        <ErrorPage
            v-else-if="isPresto"
            icon="icon-alert"
            track-error="incompatible_device_page_reached"
            :title="$t('ui.casinoLobby.gamesCantLoad')"
            :section-list="[$t('ui.casinoLobby.deviceIsIncompatible')]"
            :button="{ text: $t('ui.common.returnToHome'), emit: true }"
            @button:click="returnToHome()"
        />
        <template v-else>
            <template v-if="gameCollection.length">
                <Tabs
                    v-if="isGamesCategoriesVisible && !inProgress"
                    :tabs="gamesCategories"
                    :active="currentTab"
                    class="casino-tabs"
                    tab-type="round"
                    scrollable
                    @select="setCurrentTab"
                />
                <div class="toolbar table">
                    <div class="toolbar-slot row-cell align-middle align-left">
                        <DropDown
                            class="uppercase"
                            name="casino-lobby"
                            :label="$t('ui.casinoLobby.sort')"
                            :items="sortingItems"
                            :is-open="sortingIsOpen"
                            :selected="gamesSortBy"
                            :is-label-underline="false"
                            icon-id="icon-sort"
                            @value="setGamesSortBy($event)"
                            @toggle="handleToggleDropdown"
                        />
                    </div>
                    <div class="toolbar-slot row-cell align-middle align-right">
                        <SvgIcon
                            v-for="(button, index) in toolbarButtons"
                            :id="`casino-lobby-toolbar-${button.id}-button-click`"
                            :key="`toolbar-icon-${button.id}-${index}`"
                            class="icon-size-very-large pointer"
                            :class="{ selected: button.id === gameListLayout }"
                            :icon-id="`icon-${button.id}-view`"
                            @click.native="setGamesLayout(button.id)"
                        />
                    </div>
                </div>
            </template>
            <Modal name="casino-modal" :options="{ overlay: true }" :width="getModalWidth" :fixed="true" @close="closeModal">
                <div class="title">{{ modalName }}</div>
                <template v-if="modalType === REMOVE_GAME_FAVOURITES_MODAL_NAME">
                    <p class="description">{{ $t('ui.casinoLobby.removeFavouritesModal.description') }}</p>
                    <button
                        id="casino-lobby-remove-favourite-remove-button-click"
                        data-test-id="casino-lobby-remove-favourite-remove-button-click"
                        class="button button-primary button-full"
                        @click="removeFavoriteCasinoGames(favouriteGameIndex)"
                    >
                        {{ $t('ui.casinoLobby.removeFavouritesModal.remove') }}
                    </button>
                    <button
                        id="casino-lobby-remove-favourite-cancel-button-click"
                        data-test-id="casino-lobby-remove-favourite-cancel-button-click"
                        class="button button-accent button-full"
                        @click="closeModal"
                    >
                        {{ $t('ui.casinoLobby.removeFavouritesModal.cancel') }}
                    </button>
                </template>
                <template v-else-if="modalType === GAME_FAVOURITES_MODAL_NAME">
                    <p class="description">{{ favoritesModalText }}</p>
                    <button
                        id="casino-lobby-signup-button-click"
                        data-test-id="casino-lobby-signup-button-click"
                        class="button button-primary button-full"
                        @click="modalButtonClick('/join-now')"
                    >
                        {{ $t('ui.common.joinNow') }}
                    </button>
                    <button
                        id="casino-lobby-login-button-click"
                        data-test-id="casino-lobby-login-button-click"
                        class="button button-accent button-full"
                        @click="
                            modalButtonClick('/login', {
                                returnPath: `/${casinoPath}`,
                                filter: $route.query.filter,
                                favouriteCandidateGameId,
                            })
                        "
                    >
                        {{ $t('ui.common.login') }}
                    </button>
                </template>
                <template v-else-if="modalType === GAME_LAUNCHER_MODAL_NAME">
                    <p class="description">{{ $t('ui.casinoLobby.gameLaunchModalText') }}</p>
                    <button
                        id="casino-lobby-signup-button-click"
                        data-test-id="casino-lobby-signup-button-click"
                        class="button button-primary button-full"
                        @click="modalButtonClick('/join-now')"
                    >
                        {{ $t('ui.common.joinNow') }}
                    </button>
                    <button
                        id="casino-lobby-login-button-click"
                        data-test-id="casino-lobby-login-button-click"
                        class="button button-accent button-full"
                        @click="
                            modalButtonClick('/login', {
                                returnPath: `/${casinoPath}`,
                                gameId: modalId,
                                filter: $route.query.filter,
                            })
                        "
                    >
                        {{ $t('ui.common.login') }}
                    </button>
                </template>
                <template v-else>
                    <p class="description">{{ $t('ui.casinoLobby.gameDepositModalText') }}</p>
                    <button
                        id="casino-lobby-deposit-button-click"
                        data-test-id="casino-lobby-deposit-button-click"
                        class="button button-primary button-full"
                        @click="modalButtonClick('/deposit')"
                    >
                        {{ $t('ui.casinoLobby.depositNow') }}
                    </button>
                </template>
            </Modal>
            <Spinner v-if="inProgress" :visible="true" class="inset" />
            <template v-else>
                <div v-if="!favoriteGames.length && currentTab.key === gameCategory.FAVOURITES" class="no-favourite-game">
                    <ResponsiveImage single="one" :images="backgroundGameImage" width="120" height="120" />
                    <h3>{{ $t('ui.casinoLobby.noFavouriteGame.title') }}</h3>
                    <p>{{ $t('ui.casinoLobby.noFavouriteGame.description') }}</p>
                </div>
                <div
                    v-if="currentGameCollection.length"
                    class="field-wrapper"
                    :class="[isListStyle ? 'list' : 'tile', { 'hidden-sidebar': isRightSideBarHidden }]"
                >
                    <div
                        v-for="(game, index) in visibleGames"
                        :key="`casino-game-${game.name}-${index}`"
                        class="casino-game-wrapper pointer"
                    >
                        <div class="card-item-wrapper">
                            <div v-if="isAuthenticated" class="icon-star-wrapper" @click="addFavoriteCasinoGames({ id: game.id })">
                                <SvgIcon
                                    id="casino-lobby-favourites-button-click"
                                    :icon-id="getStarIconId({ id: game.id, gameId: game.gameId })"
                                    class="icon-star icon-size-very-large"
                                />
                            </div>
                            <div id="casino-lobby-game-tile" class="card-item" @click="gameLauncher(game)">
                                <div class="casino-img-wrapper" :class="{ 'casino-img-wrapper-shadow': !isAuthenticated }">
                                    <ResponsiveImage
                                        v-if="isGameWithImage(game)"
                                        class="casino-img card-item-image"
                                        :images="imagesByGameName[game.name]"
                                        @error="imageError.push(game.name)"
                                    />
                                    <div v-else class="casino-img-error card-item-image card-item-error">
                                        <SvgIcon class="icon-size-very-large pointer" icon-id="icon-casino" />
                                    </div>
                                </div>
                                <div class="card-item-content">
                                    <div class="card-item-text">
                                        {{ getGameName(game) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showMoreButton && !inProgress && numberGamesLeft > 0"
                    id="casino-lobby-show-more-games-click"
                    class="casino-lobby-show-more"
                    @click="showMoreGames"
                >
                    <span class="casino-lobby-show-more-button">
                        {{ $t('ui.casinoLobby.moreGames') }} <strong>{{ numberGamesLeft }}</strong>
                        <SvgIcon icon-id="arrow_down" class="icon icon-size-very-small" />
                    </span>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { deviceType, helper } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';

import { TOKEN_ERRORS } from '@/modules/platform';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';

import { DropDown, Tabs } from '@/modules/core/components';

import { mutation as generalMutation } from '@/store/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { action as casinoAction, getter as casinoGetter, mutation as casinoMutation } from '@/store/modules/casino/const';
import PageMixin from '@/components/Pages/Page.mixin';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import { casinoProviderType, gameCategory, sortType, toolbarIconType } from '@/js/casino-const';
import ErrorPage from '@/components/Pages/ErrorPage';
import { GAME_BACKGROUNDS } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';
import { defaultCasinoPath } from '@/modules/platform/const/casino-const';

const ResponsiveImage = () => import('@/components/ResponsiveImage');

const GAME_LAUNCHER_MODAL_NAME = 'game-launcher-modal';
const GAME_DEPOSIT_MODAL_NAME = 'game-deposit-modal';
const GAME_FAVOURITES_MODAL_NAME = 'game-favourites-modal';
const REMOVE_GAME_FAVOURITES_MODAL_NAME = 'remove-game-favourites-modal';

export default {
    name: 'CasinoGamesOld',
    components: { ResponsiveImage, DropDown, ErrorPage, Tabs },
    directives: {
        sniffer: scrollSniffer,
    },
    mixins: [PageMixin],
    beforeRouteLeave() {
        this.clearNotification();
    },
    data: () => ({
        imageError: [],
        toolbarButtons: [{ id: toolbarIconType.TILE }, { id: toolbarIconType.LIST }],
        showMoreButton: true,
        currentItemsRange: 0,
        visibleGames: [],
        casinoProviderType,
        gameCategory,
        sortingIsOpen: false,
        GAME_LAUNCHER_MODAL_NAME,
        GAME_DEPOSIT_MODAL_NAME,
        GAME_FAVOURITES_MODAL_NAME,
        REMOVE_GAME_FAVOURITES_MODAL_NAME,
        modalId: null,
        modalName: null,
        modalType: null,
        favouriteGameIndex: null,
        isWeb: deviceType.isWeb(),
        isPresto: deviceType.isPresto(),
        backgroundGameImage: GAME_BACKGROUNDS,
        favouriteCandidateGameId: null,
        currentGame: null,
        currentGameId: null,
        errorModalType: null,
    }),
    computed: {
        ...mapState({
            gamesSortBy: (state) => state.casino.gamesSortBy,
            gameLaunchError: (state) => state.casino.gameLaunchError,
            gameLoadError: (state) => state.casino.error,
            gameListLayout: (state) => state.casino.layout,
            chips: (state) => state.platform.settings.chips,
        }),
        ...mapGetters({
            gameCollection: casinoGetter.GET_GAME_COLLECTION,
            favoriteGamesIds: casinoGetter.GET_FAVORITE_GAMES_IDS,
            favoriteGames: casinoGetter.GET_FAVORITE_GAMES,
            isLoading: coreGetter.IS_LOADING,
            settings: platformGetter.GET_SETTINGS,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            balance: authGetter.GET_BALANCE,
            chipsBalance: authGetter.GET_CHIPS_BALANCE,
            casinoName: translationsGetter.CASINO_NAME,
            gameCollectionByCategories: casinoGetter.GET_GAME_COLLECTION_BY_CATEGORIES,
            gameCategoryByNames: casinoGetter.GET_GAME_CATEGORY_BY_NAMES,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            country: platformGetter.GET_COUNTRY,
            sideBarDisabledPages: platformGetter.GET_SIDE_BAR_DISABLED_PAGES,
        }),
        casinoPath() {
            return helper.getObjectField(this.brandPreference, 'casinoType.path') || defaultCasinoPath;
        },
        isGamesCategoriesVisible() {
            const { brandGamingIntegration } = this.settings;
            return helper.getObjectField(brandGamingIntegration, 'enabled', false);
        },
        favoritesModalText() {
            return this.currentTab.key === gameCategory.FAVOURITES
                ? this.$t('ui.casinoLobby.favouritesModal.description')
                : this.$t('ui.casinoLobby.notFavouritesTabModal.description');
        },
        gamesCategories() {
            const defaultCategories = [
                {
                    key: gameCategory.FAVOURITES,
                    iconId: 'icon-star-tab',
                    iconClass: 'icon-size-small',
                },
                { key: gameCategory.ALL, text: this.$t('menus.all') },
            ];

            const categories = Object.keys(this.gameCollectionByCategories)
                .map((categoryName) => {
                    const { text, position } = this.gameCategoryByNames[categoryName] || {};
                    return {
                        key: categoryName,
                        text: text || categoryName,
                        position: position || 0,
                    };
                })
                .sort((a, b) => a.position - b.position);
            return [...defaultCategories, ...categories];
        },
        currentGameCollection() {
            switch (this.currentTab.key) {
                case gameCategory.ALL:
                    return this.gameCollection;
                case gameCategory.FAVOURITES:
                    return this.favoriteGames;
                default:
                    return this.gameCollectionByCategories[this.currentTab.key] || [];
            }
        },
        gameList() {
            const games = [...this.currentGameCollection];
            const sortByName = (a, b) => {
                const { name: nameA, lobbyName: lobbyNameA } = a;
                const { name: nameB, lobbyName: lobbyNameB } = b;

                return (lobbyNameA || nameA).localeCompare(lobbyNameB || nameB);
            };
            const getPosInFavoriteList = (item) => {
                const { id } = item;
                return this.favoriteGamesIds.findIndex((favId) => String(favId) === String(id));
            };
            const sortByFavoriteIds = (a, b) => {
                const aPosInFavoriteList = getPosInFavoriteList(a);
                const bPosInFavoriteList = getPosInFavoriteList(b);
                return bPosInFavoriteList > -1 && aPosInFavoriteList > -1
                    ? aPosInFavoriteList - bPosInFavoriteList
                    : bPosInFavoriteList - aPosInFavoriteList;
            };
            switch (this.gamesSortBy) {
                case sortType.POPULAR:
                    return games.sort((a, b) => {
                        return a.position > b.position ? 1 : a.position < b.position ? -1 : 0;
                    });
                case sortType.NAME_ASC:
                    return games.sort(sortByName);
                case sortType.NAME_DESC:
                    return games.sort(sortByName).reverse();
                case sortType.FAVOURITES_ADDED_ASC:
                    return games.sort(sortByFavoriteIds);
                default:
                    return games;
            }
        },
        sortingItems() {
            const hasFavorites =
                this.favoriteGamesIds.length &&
                !this.currentGameCollection.includes(undefined) &&
                this.currentGameCollection.some(({ id }) => this.favoriteGamesIds.find((favId) => String(id) === String(favId)));
            return [
                { key: this.$t('ui.casinoLobby.popular'), value: sortType.POPULAR },
                ...((this.isAuthenticated &&
                    hasFavorites &&
                    this.currentTab.key !== gameCategory.FAVOURITES && [
                        {
                            key: this.$t('ui.casinoLobby.favourites'),
                            value: sortType.FAVOURITES_ADDED_ASC,
                        },
                    ]) ||
                    []),
                { key: this.$t('ui.casinoLobby.ascending'), value: sortType.NAME_ASC },
                { key: this.$t('ui.casinoLobby.descending'), value: sortType.NAME_DESC },
            ];
        },
        isListStyle() {
            return this.gameListLayout === toolbarIconType.LIST;
        },
        numberGamesLeft() {
            return this.currentGameCollection.length - this.visibleGames.length || 0;
        },
        inProgress() {
            return this.isLoading(casinoAction.GET_CASINO_GAMES);
        },
        nextVisibleItemsRange() {
            return this.currentItemsRange + this.nextItemsRange;
        },
        visibleGamesLength() {
            return this.gameList.length < this.nextVisibleItemsRange ? this.gameList.length : this.nextVisibleItemsRange;
        },
        getModalWidth() {
            return this.modalType === GAME_LAUNCHER_MODAL_NAME ? '312px' : '330px';
        },
        hasGameLoadError() {
            return this.gameLoadError;
        },
        nextItemsRange() {
            return this.isWeb ? 30 : 15;
        },
        currentTab() {
            return {
                key: this.$route.query.filter || gameCategory.ALL,
            };
        },
        imagesByGameName() {
            const imageSizesObj = { SMALL: 100, MEDIUM: 125, LARGE: 200, null: 100 };
            const imageSizesValue = Object.values(imageSizesObj);
            const maxNumberOfImages = imageSizesValue.length;

            return this.gameCollection.reduce((imagesByGameName, game) => {
                const imagesArr = helper.getObjectField(game, 'images', []);
                const gameThumbsSet = imagesArr.length > 0 ? imagesArr : game.thumbUrl;

                if (gameThumbsSet.length === 0) return imagesByGameName;

                const gameImages = gameThumbsSet
                    .slice(0, maxNumberOfImages)
                    .map((image, index) => ({
                        url: image.url || image,
                        width: imagesArr.length ? imageSizesObj[image.size] : imageSizesValue[index],
                    }))
                    .sort((a, b) => a.width - b.width);

                return {
                    ...imagesByGameName,
                    [game.name]: gameImages,
                };
            }, {});
        },
        isRightSideBarHidden() {
            return this.sideBarDisabledPages.includes(this.$route.path);
        },
    },
    watch: {
        gamesSortBy() {
            this.visibleGames = this.gameList.slice(0, this.visibleGamesLength);
        },
        inProgress(gamesWasLoaded) {
            if (!gamesWasLoaded) {
                this.visibleGames = this.gameList.slice(this.currentItemsRange, this.nextItemsRange);
                this.launchGameByLink();
            }
        },
        gameLaunchError(value) {
            if (TOKEN_ERRORS.includes(value?.errorCode)) {
                const option = this.errorModalType === GAME_LAUNCHER_MODAL_NAME ? this.currentGame : this.currentGameId;
                this.openModal(this.errorModalType, option);
            } else {
                !!value &&
                    this.$modal.show(
                        {
                            template: `<p style="padding-bottom: 15px; text-align: center;">{{$t('ui.casinoLobby.gameLaunchUnexpectedError')}}</p>`,
                        },
                        {},
                        { name: `${GAME_LAUNCHER_MODAL_NAME}-error`, options: { overlay: true } }
                    );
            }
        },
        '$route.query.gameId'() {
            this.launchGameByLink();
        },
        'settings.brand': {
            immediate: true,
            handler() {
                if (this.isPresto) {
                    return;
                }
                this.$store.dispatch(casinoAction.GET_CASINO_GAMES);
            },
        },
        '$route.query.filter': {
            immediate: true,
            handler() {
                if (this.$modal.active.length) {
                    this.closeModal();
                }

                const currentQueryParams = { ...this.$route.query };
                const { filter, favouriteCandidateGameId } = currentQueryParams;
                const correctFavouriteCandidateGameId = !this.favoriteGamesIds.find((id) => id === favouriteCandidateGameId);
                const hasFavouriteCandidateGameId = favouriteCandidateGameId && this.isAuthenticated;
                if (hasFavouriteCandidateGameId) {
                    if (correctFavouriteCandidateGameId) {
                        this.addFavoriteCasinoGames({ id: favouriteCandidateGameId });
                    }
                    delete currentQueryParams.favouriteCandidateGameId;
                }
                if (!filter || hasFavouriteCandidateGameId) {
                    if ([routeName.CASINO, routeName.GAMES].includes(this.$route.name)) {
                        this.$router.replace({
                            name: this.$route.name,
                            query: {
                                ...currentQueryParams,
                                filter: !filter ? gameCategory.ALL : filter,
                            },
                        });
                    }
                }

                this.visibleGames = this.gameList.slice(0, this.visibleGamesLength);
                if (!this.isAuthenticated && this.$route.query.filter === gameCategory.FAVOURITES) {
                    this.openModal(GAME_FAVOURITES_MODAL_NAME);
                }
            },
        },
    },
    created() {
        this.$gtm.query({ event: 'casino_lobby_page_view' });
    },
    methods: {
        ...mapActions({
            addFavorite: casinoAction.ADD_FAVORITE_GAME,
        }),
        ...mapMutations({
            setGamesSortBy: casinoMutation.SET_GAMES_SORT_BY,
            setLayout: casinoMutation.SET_CASINO_LAYOUT,
            clearNotification: generalMutation.CLEAR_NOTIFICATIONS,
        }),
        setGamesLayout(layout) {
            this.$gtm.query({
                event: 'casino_lobby_layout_icon_click',
                layout_type: layout,
            });
            this.setLayout(layout);
        },
        handleToggleDropdown() {
            this.sortingIsOpen = !this.sortingIsOpen;
        },
        modalButtonClick(path, query) {
            this.$router.push({ path, query });
        },
        getGameName(game) {
            return game.lobbyName || game.name || '';
        },
        addFavoriteCasinoGames({ id }) {
            this.currentGameId = id;
            this.errorModalType = GAME_FAVOURITES_MODAL_NAME;

            if (this.isAuthenticated) {
                const newFavorites = [...this.favoriteGamesIds];
                const gameIndex = newFavorites.findIndex((favId) => String(favId) === String(id));
                if (gameIndex >= 0) {
                    this.openModal(REMOVE_GAME_FAVOURITES_MODAL_NAME, {
                        name: this.$t('ui.casinoLobby.removeFavouritesModal.title'),
                        favouriteGameIndex: gameIndex,
                    });
                    return;
                } else {
                    newFavorites.unshift(id);
                    this.$gtm.query({
                        event: 'casino_lobby_add_favourites_button_click',
                        jurisdiction: this.country,
                        isAuthenticated: this.isAuthenticated,
                    });
                }
                this.addFavorite(newFavorites);
            } else {
                this.favouriteCandidateGameId = id;
                this.openModal(GAME_FAVOURITES_MODAL_NAME, { name: this.$t('ui.casinoLobby.favouritesModal.title') });
            }
        },
        removeFavoriteCasinoGames(gameIndex) {
            const newFavorites = [...this.favoriteGamesIds];
            newFavorites.splice(gameIndex, 1);
            this.$gtm.query({
                event: 'casino_lobby_remove_favourites_button_click',
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
            });
            this.addFavorite(newFavorites).then(() => {
                if (this.$route.query.filter === gameCategory.FAVOURITES) {
                    this.updateVisibleGames();
                }
            });
            this.closeModal();
        },
        getStarIconId({ id }) {
            return this.favoriteGamesIds.find((favId) => String(id) === String(favId)) ? 'icon-star-active' : 'icon-star';
        },
        gameLauncher(game) {
            this.currentGame = game;
            this.errorModalType = GAME_LAUNCHER_MODAL_NAME;
            const { name, provider, providerGameId } = game || {};
            const hasNoBetpawaChips = this.isAuthenticated && this.chips && this.chipsBalance < this.settings.betting.real.minStake;
            const hasNoBalance = this.isAuthenticated && !this.chips && this.balance <= 0;

            if (hasNoBetpawaChips) {
                this.$modal.show('chips-modal');
                return;
            }

            if (!this.isAuthenticated || hasNoBalance) {
                this.openModal(hasNoBalance ? GAME_DEPOSIT_MODAL_NAME : GAME_LAUNCHER_MODAL_NAME, game);
                return;
            }

            this.$store.dispatch(casinoAction.OPEN_CASINO_GAME, { name, provider, providerGameId });
        },
        showMoreGames() {
            this.showMoreButton = !this.showMoreButton;
            this.loadMoreCasinoItems();
        },
        updateVisibleGames() {
            const currentGameList = [...this.gameList];
            this.visibleGames = this.gameList.slice(0, this.visibleGamesLength);
            if (currentGameList.length <= this.visibleGamesLength) {
                this.showMoreButton = false;
            }
        },
        loadMoreCasinoItems() {
            if (!this.numberGamesLeft) {
                return;
            }
            this.currentItemsRange += this.nextItemsRange;
            const list = this.gameList.slice();
            const newList = list.slice(this.currentItemsRange, this.nextVisibleItemsRange);
            this.visibleGames.push.apply(this.visibleGames, newList);
        },
        onSnifferLoadMore() {
            if (this.showMoreButton) {
                return;
            }
            this.loadMoreCasinoItems();
            this.$gtm.query({
                event: 'casino_lobby_game_list_auto_scrolling_triggered',
                gamesLoadedCount: this.visibleGames.length,
            });
        },
        openModal(modalName, { name, gameId, favouriteGameIndex, lobbyName } = {}) {
            if (gameId) {
                this.modalId = gameId;
            }
            if (name) {
                this.modalName = lobbyName || name;
            }
            switch (modalName) {
                case GAME_FAVOURITES_MODAL_NAME:
                    this.modalType = GAME_FAVOURITES_MODAL_NAME;
                    this.modalName =
                        this.currentTab.key === gameCategory.FAVOURITES
                            ? this.$t('ui.casinoLobby.favouritesModal.title')
                            : this.$t('ui.casinoLobby.notFavouritesTabModal.title');
                    break;
                case GAME_LAUNCHER_MODAL_NAME:
                    this.modalType = GAME_LAUNCHER_MODAL_NAME;
                    break;
                case REMOVE_GAME_FAVOURITES_MODAL_NAME:
                    this.favouriteGameIndex = favouriteGameIndex;
                    this.modalType = REMOVE_GAME_FAVOURITES_MODAL_NAME;
                    break;
                default:
                    this.modalType = GAME_DEPOSIT_MODAL_NAME;
                    break;
            }

            this.$modal.show('casino-modal');
        },
        closeModal() {
            this.$modal.hide('casino-modal');
            this.modalType = this.modalId = this.modalName = null;
            this.resetGameIdParam();
        },
        resetGameIdParam() {
            const { query } = this.$route;
            if (query?.gameId) {
                // fix for NavigationDuplicated error
                this.$router.replace({ ...this.$route, query: { ...query, gameId: '' } });
            }
        },
        returnToHome() {
            this.$router.push('/');
        },
        setCurrentTab({ key }) {
            if (key && key !== this.currentTab.key) {
                this.$gtm.query({
                    event: 'casino_categories_tab',
                    category_name: key,
                    jurisdiction: this.country,
                    isAuthenticated: this.isAuthenticated,
                });

                this.$router.push({
                    name: this.$route.name,
                    query: {
                        ...this.$route.query,
                        filter: key,
                    },
                });
            }
        },
        errorButtonClick() {
            window.location.reload();
        },
        launchGameByLink() {
            const { gameId: queryId } = this.$route.query;
            if (queryId && this.currentGameCollection.length) {
                const game = this.currentGameCollection.find(({ id, gameId }) => String(id) === String(queryId) || gameId === queryId);
                if (game) {
                    this.$nextTick(() => {
                        this.gameLauncher(game);
                    });
                }
            }
        },
        isGameWithImage({ name }) {
            return !this.imageError.includes(name) && this.imagesByGameName[name];
        },
    },
};
</script>

<style scoped lang="scss">
.casino-lobby {
    margin-top: -12px !important;

    .casino-header + & {
        border-top: 0;
        margin-top: 0;
    }

    .page-headline {
        text-align: center;
    }

    &-show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        margin: 25px 0;
        border-top: 1px solid #e6e7e2;
        border-bottom: 1px solid #e6e7e2;

        &-button {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #191a1a;
            border-bottom: 1px solid #191a1a;
            cursor: pointer;

            strong {
                display: inline-block;
                margin: 0 5px 0 10px;
            }

            svg {
                margin-top: -3px;
            }
        }
    }

    .modal-mask {
        .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: $grey-text;
            margin-bottom: 8px;
        }

        .description {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $grey-text;
            margin-bottom: 24px;
        }

        .button {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.field-wrapper {
    margin-top: 2px;
    background: $white-bg;
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    padding: 0;

    @include oldschooltowp {
        grid-template-columns: repeat(3, calc(100% / 3));
    }

    @include wptose {
        grid-template-columns: repeat(4, calc(100% / 4));
    }

    @include setofull {
        grid-template-columns: repeat(5, calc(100% / 5));
    }

    &.hidden-sidebar {
        @include tablet {
            grid-template-columns: repeat(6, calc(100% / 6));
        }
    }

    .card-item-wrapper {
        position: relative;
        width: 100%;
    }

    &.list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex-direction: column;
        padding: 0 0 12px;
    }

    &.tile {
        margin: 0 4px;

        @include oldschoolbasic {
            margin: 0 8px;
        }

        @include basictosmtablet {
            margin: 0 6px;
        }
    }
}

.casino-game-wrapper {
    position: relative;

    .icon-star-wrapper {
        position: absolute;
        width: 44px;
        height: 44px;
        top: 0;
        right: 0;
        z-index: 2;
        cursor: pointer;
        text-align: right;
        padding: 5px 5px 0 0;

        &:hover .icon-star {
            fill: $green;
        }
    }

    .icon-star {
        fill: #f4f5f0;
    }

    @at-root .tile #{&} {
        margin: 8px;
        display: flex;
        justify-content: center;

        @include oldschoolbasic {
            margin: 8px 4px;
        }

        @include basictosmtablet {
            margin: 8px 6px;
        }
    }

    @at-root .list #{&} {
        .icon-star-wrapper {
            right: 16px;
            top: 50%;
            margin-top: -22px;
            padding-top: 10px;
        }

        .icon-star {
            fill: $grey-text;
        }
    }
}

.card-item {
    display: inline-flex;
    flex-direction: column;
    width: inherit;

    &:hover {
        color: $primary-color;
    }

    &-game-launcher {
        position: absolute;
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    &-content {
        flex-direction: column;
        justify-content: center;
        padding-top: 8px;
    }

    &-text {
        @extend %body-small-font-400;
        display: block;
        border-radius: $border-radius-bottom;
        text-align: left;
        background: white;
        margin-bottom: 4px;

        & > div + div {
            color: $disabled-text;
        }
    }

    &-category {
        @extend %small-details-font-400;
        display: block;
        color: $disabled-text;
        background: white;
    }

    .field-wrapper .casino-img-wrapper &-error {
        border: 1px solid $border-color;
        width: 100%;
    }

    @at-root .casino-game-wrapper:last-child &-content {
        border: none;
    }

    .casino-img-wrapper {
        display: flex;
        justify-content: center;
        overflow: hidden;

        @at-root .tile #{&} {
            &:before {
                display: block;
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(224.07deg, rgba(0, 0, 0, 0.6) 3.22%, rgba(0, 0, 0, 0) 40.99%);
                z-index: 1;
            }

            &-shadow:hover:before,
            &:hover:before {
                display: block;
                background: linear-gradient(224.07deg, rgba(0, 0, 0, 0.4) 10.95%, rgba(0, 0, 0, 0) 81.24%);
            }

            &-shadow:before {
                display: none;
            }
        }
    }

    @at-root .list #{&} {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;

        &-text {
            padding: 0;
        }

        &-content {
            flex: 1 1 auto;
            display: flex;
            align-items: inherit;
            margin-right: 12px;
            border-bottom: 1px solid $border-color;
        }

        &:before,
        .casino-img-wrapper {
            margin: 12px;
            width: 88px;
            height: 88px;

            @include maxmain {
                width: 64px;
                height: 64px;
            }
        }

        &:before {
            display: none;
        }

        &:hover:before {
            display: block;
        }

        &-image {
            object-fit: cover;
            width: 88px;
            height: 88px;

            @include maxmain {
                width: 64px;
                height: 64px;
            }
        }
    }

    @at-root .tile #{&} {
        .casino-img-wrapper {
            padding-top: 100%;
            position: relative;
        }

        &-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            object-fit: cover;
        }
    }
}

img.casino-img,
.casino-img-error {
    border-radius: $border-radius-top;
}

.casino-img-error {
    background: $light-grey-bg;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
        fill: #aaaeb0;
        height: 36px;
        width: 36px;
    }
}

.toolbar {
    margin-top: 16px;
    padding: 0 10px;

    &-slot > *:not(:last-child) {
        margin-right: 10px;
    }

    svg.svg-icon.selected {
        fill: $menu-item-color-selected;
    }
}

.casino-tabs {
    margin-top: 20px;

    ::v-deep .tabs-menu {
        padding: 0 12px;
    }
}

.no-favourite-game {
    text-align: center;
    padding: 32px 0 56px;

    h3 {
        padding: 24px 0 10px;
        margin: 0;
    }

    p {
        padding: 0 10px;
    }
}

::v-deep .menu-item > .link {
    text-transform: initial;
}

::v-deep .tab-favourites {
    svg {
        position: relative;
        top: -2px;
        margin-right: 5px;
        fill: $betpawa-black;
    }

    &.active svg {
        fill: $menu-item-color-selected;
    }
}
</style>
