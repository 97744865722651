<template>
    <Modal
        class="session-expired-modal"
        :width="isPresto ? '100%' : '320px'"
        :name="modalName"
        :options="{ backDrop: !isPresto }"
        is-empty-header
        is-highest
        fixed
        @close="closeModal"
    >
        <div class="session-expired-modal-body">
            <div class="session-expired-modal-title">{{ $t('ui.sessionExpired.title') }}</div>
            <div class="session-expired-modal-description">{{ $t('ui.sessionExpired.description') }}</div>
            <div class="session-expired-modal-footer">
                <router-link :to="{ name: routeName.LOGIN }" class="button button-primary button-full" @click.native.capture="closeModal">
                    {{ $t('ui.common.login') }}
                </router-link>
                <button class="session-expired-modal-close underline" @click="closeModal">{{ $t('ui.common.close') }}</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import { deviceType } from '@/modules/core';
import { routeName } from '@/router/const-name';
import { mutation as authMutation } from '@/modules/platform/store/modules/auth/const';
import { mapMutations } from 'vuex';

export default {
    name: 'SessionExpiredModal',
    components: {},
    data() {
        return {
            modalName: 'session-expired-modal',
            isPresto: deviceType.isPresto(),
            routeName,
        };
    },
    methods: {
        ...mapMutations({
            setSessionExpired: authMutation.SET_SESSION_EXPIRED,
        }),
        closeModal() {
            this.setSessionExpired(false);
            this.$modal.hide('session-expired-modal');
        },
    },
};
</script>

<style lang="scss">
.session-expired-modal {
    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &-title {
        @extend %h4-font-700;
    }

    &-description {
        @extend %body-normal-font-400;
        margin-top: 4px;
    }

    &-footer {
        width: 100%;
        margin-top: 16px;
    }

    .button {
        width: 100%;
    }

    &-close {
        @extend %generic_button;
        @extend %body-normal-text;
        font-weight: 500;
        padding: 4px;
        margin-top: 8px;
        width: 100%;
        text-transform: none;
        background-color: transparent;
    }
}
</style>
