<template>
    <div class="main-search">
        <Spinner :listen="spinnerTrigger" />
        <Headline :title="$t('ui.search.events')" icon="icon-clock" />
        <div v-if="searchResults.events.length">
            <div v-for="event in searchResults.events" :key="'event-search-' + event.id">
                <Game :event="event" :show-market-count="true" />
            </div>
        </div>
        <div v-else class="page-text-container">
            {{ $t('ui.search.noEventsActive') }}
        </div>
        <div v-if="searchResults.groups.length">
            <Headline :title="$t('ui.search.leagues')" icon="icon-tournament" />
            <div
                v-for="({ competition, region, category }, index) in searchResults.groups"
                :key="'tournament-search-' + index"
                class="tournament-container"
            >
                <router-link :to="{ name: routeGroupName, params: { id: competition.id }, query: { categoryId: category.id } }">
                    <h3 class="tournament-title">{{ competition.name }}</h3>
                    <h4 class="tournament-region">{{ region.name }} ({{ category.name }})</h4>
                </router-link>
            </div>
        </div>
        <HotDescription v-if="searchResults.containsHot" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { action as sportAction } from '@/modules/sport/store/const';

import HotDescription from '@/components/HotDescription';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import Game from '../Game.vue';
import { routeName } from '@/router/const-name';

export default {
    name: 'SearchView',
    components: { Game, HotDescription },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            routeGroupName: routeName.GROUP,
            spinnerTrigger: sportAction.GET_SEARCH_RESULTS,
        };
    },
    computed: mapState({
        searchResults: (state) => state.sport.sports,
    }),
    watch: {
        '$route.query.searchQuery'() {
            this.initiateSearch();
        },
    },
    created() {
        this.$router.onReady(() => {
            if (this.$route.query.searchQuery) {
                this.initiateSearch();
            }
        });
    },
    methods: {
        initiateSearch() {
            this.$store.dispatch(sportAction.GET_SEARCH_RESULTS, this.$route.query.searchQuery);
        },
    },
};
</script>

<style scoped lang="scss">
.tournament-container {
    text-align: left;
    background-color: $light-grey-bg;
    padding: 10px;
    margin: 0 0 2px 0;
}

.tournament-title {
    font-weight: normal;
    position: relative;
    margin: 0;
}

.tournament-region {
    font-size: 12px;
    line-height: 1em;
    color: $grey-text;
    margin: 5px 0 0 0;
}
</style>
