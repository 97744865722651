export const widgetSize = {
    NORMAL: 'normal',
    COMPACT: 'compact',
};

export const cloudflareEvents = {
    INIT: 'init',
    REQUEST_EXTRA_PARAMS: 'requestExtraParams',
    TRANSLATION_INIT: 'translationInit',
    COMPLETE: 'complete',
    WIDGET_STATE: 'widgetStale',
    OVERRUN_BEGIN: 'overrunBegin',
    OVERRUN_END: 'overrunEnd',
};
