<template>
    <div class="back-to-top" :class="{ 'back-to-top-hidden': isHidden }" @click="scrollToTop">
        <span class="back-to-top-item">
            <SvgIcon icon-id="icon-arrow-up" class="back-to-top-icon icon-size-medium" />
        </span>
        <span class="back-to-top-item back-to-top-label">{{ $t('ui.common.backToTop') }}</span>
    </div>
</template>

<script>
export default {
    name: 'BackToTop',
    props: {
        isHidden: {
            type: Boolean,
        },
    },
    methods: {
        scrollToTop() {
            this.$scroll.scrollTo(0);
        },
    },
};
</script>

<style scoped lang="scss">
.back-to-top {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    border-radius: 6px;
    background: #191a1acc;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &-hidden {
        opacity: 0;
        pointer-events: none;
    }

    &-item {
        display: flex;
        padding: 0 2px;
    }

    &-icon {
        fill: $light-grey;
        padding: 2px 0;
    }

    &-label {
        @extend %small-details-font-700;
        color: $light-grey;
    }
}
</style>
