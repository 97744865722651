import { helper } from '@/modules/core';
import { scrollDirection } from '@/js/scroll-direction-const';

export default {
    inserted: (el, binding, vnode) => {
        const target = vnode.context.$scroll.getScroll();
        const { handler, distance, disabled, debounceTimeout, anyDistance, direction } = binding.value;
        let lastScroll = 0;
        const eventHandler = (event) => {
            const currentScrollTop = Math.max(event.target.scrollTop, 0);
            const currentDirection = currentScrollTop > lastScroll ? scrollDirection.DOWN : scrollDirection.UP;
            lastScroll = currentScrollTop;
            const passDirectionCondition = !direction || direction === currentDirection;
            if (
                passDirectionCondition &&
                (anyDistance || el.scrollHeight - event.target.scrollTop - event.target.offsetHeight < (distance || 250)) &&
                typeof handler === 'function'
            ) {
                handler(currentDirection, currentScrollTop);
            }
        };

        if (disabled || !target) {
            return;
        }

        const debounceHandler = helper.debounce(eventHandler, debounceTimeout);
        const scrollHandler = debounceTimeout ? debounceHandler : eventHandler;

        target.addEventListener('scroll', scrollHandler);
        el.$destroy = () => {
            target.removeEventListener('scroll', scrollHandler);
        };
    },
    unbind: (el, { value }) => {
        !value.disabled && el.$destroy();
    },
};
