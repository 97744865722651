<template>
    <Modal
        v-if="winTier"
        class="big-win-modal"
        name="big-win-modal"
        :click-outside="false"
        :options="{ overlay: true }"
        :width="'100%'"
        @close="closeModal"
    >
        <div class="big-win-notification">
            <ImageIcon :src="winTier.trophyImg" class="trophy-img" />
            <div class="winning-phrase">{{ winTier.tierTitle }}</div>
            <div class="congratulatory-phrase">{{ winTier.congratulatoryPhrase }}</div>
            <Currency :contrast="false" :amount="lastWinInfo.lastWinBetPayout" :format="settings.currency" class="currency-text" />
            <div class="odds-text">{{ $t('ui.bigWin.withOdds', { odds: lastWinInfo.lastWinBetPrice }) }}</div>
            <router-link
                :to="{ name: routeName.BETSLIP, params: { id: lastWinInfo.lastWinBetId, betslipType: betslipType.REGULAR } }"
                class="button button-primary button-view-betslip"
                @click.native="trackViewBetslipLinkClick"
            >
                {{ $t('ui.bigWin.viewBetslip') }}
            </router-link>
            <Checkbox
                :checked="isNotificationChecked"
                :is-loading="isNotificationSetLoading"
                class="notification-checkbox"
                @onCheck="notificationChecked"
            >
                <span>{{ $t('ui.bigWin.notificationSwitchText', { days: notificationDisablePeriod }) }}</span>
            </Checkbox>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import Currency from '@/modules/core/components/molecules/Currency.vue';
import Checkbox from '@/components/Checkbox.vue';
import { getter as platformGetter, action as platformAction, mutation as platformMutation } from '@/modules/platform/store/const';
import { getBetSlipWinTier } from '@/modules/sport/utils/betslip/getBetslipWinTier';
import { getObjectField } from '@/modules/core/utils/helper';
import { routeName } from '@/router/const-name';
import { betslipType } from '@/modules/sport';

export default {
    name: 'BetSlipBigWinNotificationModal',
    components: { Checkbox, Currency },
    data() {
        return {
            betslipType,
            isNotificationChecked: false,
            isNotificationSetLoading: false,
        };
    },
    computed: {
        routeName() {
            return routeName;
        },
        ...mapGetters({
            settings: platformGetter.GET_SETTINGS,
            bigWin: platformGetter.GET_BIG_WIN,
            lastWinInfo: platformGetter.GET_LAST_WIN_INFO,
            user: platformGetter.GET_USER_SETTINGS,
        }),
        winTier() {
            const { tiers = [] } = this.bigWin;
            return getBetSlipWinTier(tiers, this.lastWinInfo.lastWinBetPrice);
        },
        notificationDisablePeriod() {
            return getObjectField(this.bigWin, 'notificationDisablePeriodDays');
        },
    },
    mounted() {
        if (this.winTier) this.$modal.show('big-win-modal');
    },
    methods: {
        ...mapMutations({
            setLastSeenWinBetId: platformMutation.SET_LAST_SEEN_WIN_BET_ID,
        }),
        notificationChecked(isChecked) {
            this.isNotificationSetLoading = true;

            this.$store
                .dispatch(platformAction.PUT_DERIVED_DATA, {
                    key: 'disable_big_win_notification_date',
                    value: isChecked ? new Date() : new Date(0),
                })
                .finally(() => {
                    this.isNotificationChecked = isChecked;
                    this.isNotificationSetLoading = false;
                    if (isChecked) {
                        this.$gtm.query({
                            event: 'big_win_disable_notification',
                            userId: this.user.userUuid,
                        });
                    }
                });
        },
        closeModal() {
            // register notification modal as seen
            this.$store.dispatch(platformAction.PUT_DERIVED_DATA, {
                key: 'last_seen_win_bet_id',
                value: this.lastWinInfo.lastWinBetId,
            });
            this.setLastSeenWinBetId(this.lastWinInfo.lastWinBetId);
            this.$modal.hide('big-win-modal');
        },
        trackViewBetslipLinkClick() {
            this.$gtm.query({
                event: 'big_win_view_betslip_link_click',
                userId: this.user.userUuid,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.big-win-modal {
    ::v-deep .outside-spacing {
        max-width: 100vw;
        max-height: 100vh;
        margin: 0;

        .modal-container {
            overflow: visible;
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(5px);
            border-radius: 16px 16px 0 0;
            position: absolute;
            bottom: 0;

            .modal-body {
                overflow: visible;
            }

            .modal-header-button {
                margin: 17px;
            }
        }
    }
}

::v-deep .checkbox-label {
    align-items: center;
}

.big-win-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 30px;

    .button-view-betslip {
        @extend %body-normal-font-700;
        text-transform: uppercase;
        margin: 16px 0 0;
        padding: 7px 12px;
        width: 176px;
        text-align: center;
    }

    .winning-phrase {
        @extend %h4-font-700;
        margin-bottom: 12px;
        text-align: center;
    }

    .odds-text,
    .congratulatory-phrase {
        @extend %body-normal-font-400;
        text-align: center;
    }

    .currency-text {
        @extend %h1-font-700;
    }

    .notification-checkbox {
        display: block;
        margin: 24px 0 0;
        text-align: center;
    }

    .trophy-img {
        position: absolute;
        width: 200px;
        height: auto;
        top: -182px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

@include max-height(530px) {
    .big-win-notification {
        padding-top: 0;

        .trophy-img {
            width: 26vh;
            top: -26vh;
        }
        .notification-checkbox {
            margin-top: 5px;
        }
        .winning-phrase {
            margin-bottom: 0;
            line-height: 18px;
        }
        .currency-text {
            font-size: 18px;
            line-height: 20px;
        }
    }
    .big-win-modal ::v-deep .outside-spacing {
        .modal-container {
            padding-bottom: 10px;
            .modal-body {
                padding-top: 0;
            }
            .modal-header-button {
                margin: 10px;
            }
        }
    }
}
</style>
