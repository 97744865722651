<template>
    <div v-if="isQuickLinkDisplayable" class="quick-links">
        <ul class="nav" :class="{ 'nav-small': listQuickMenuItems.length === minMenuItems }">
            <li
                v-for="(menuItem, index) in listQuickMenuItems"
                :key="menuItem.pageId + '-quick-links-' + index"
                :style="{ width: 100 / listQuickMenuItems.length + '%' }"
                class="quick-links-menu-item"
                @click="trackLinkClick(menuItem)"
            >
                <router-link :to="{ path: menuItem.url }" class="quick-links-menu-link">
                    <SvgIcon :icon-id="menuItem.quickLink.icon" class="quick-links-icon" />
                    <div class="quick-links-menu-text">{{ $t(menuItem.quickLink.title, { casinoName }) }}</div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { deviceType, helper } from '@/modules/core';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { getter as storeGetter } from '@/store/const';
import { getter as sportGetter } from '@/modules/sport/store/const';
import { routeName } from '@/router/const-name';

export default {
    name: 'QuickLinks',
    data() {
        return {
            isMenuShown: true,
            isPresto: deviceType.isPresto(),
            minMenuItems: 3,
            maxMenuItems: 4,
        };
    },
    computed: {
        ...mapState({
            settings: (state) => state.platform.settings,
        }),
        ...mapGetters({
            casinoName: translationsGetter.CASINO_NAME,
            quickMenu: storeGetter.GET_QUICK_MAIN_MENU,
            liveEventsTotalCount: sportGetter.GET_LIVE_EVENTS_TOTAL_COUNT,
        }),
        isQuickLinkDisplayable() {
            return !this.isPresto && !this.$mq.isSSmallest && this.listQuickMenuItems.length;
        },
        listQuickMenuItems() {
            const filteredQuickMenu = this.quickMenu.filter(
                (menuItem) => menuItem.isQuickMenuLink && this.shouldDisplay(menuItem) && this.shouldDisplayLive(menuItem)
            );
            const sortedQuickMenu = this.sizeOfMenu(
                filteredQuickMenu.sort((a, b) => (a.quickLink.priority > b.quickLink.priority ? 1 : -1))
            );
            return sortedQuickMenu;
        },
        activeLinks() {
            return this.quickMenu.map((menuItem) => menuItem.pageId);
        },
    },
    methods: {
        sizeOfMenu(menu) {
            return this.$mq.isXXSSmallMin ? menu.slice(0, this.maxMenuItems) : menu.slice(0, this.minMenuItems);
        },
        shouldDisplay(menuItem) {
            return (
                (!menuItem.enabler || helper.pathChecker(menuItem.enabler, this.settings)) &&
                (!menuItem.quickLinkEnabler || helper.pathChecker(menuItem.quickLinkEnabler, this.settings))
            );
        },
        shouldDisplayLive(menuItem) {
            return this.liveEventsTotalCount ? menuItem : menuItem.pageId !== routeName.LIVE;
        },
        trackLinkClick(menuItem) {
            this.$gtm.query({
                event: `quick_link_clicked`,
                number_of_icons: this.listQuickMenuItems.length,
                icon_clicked: menuItem.pageId.toLowerCase(),
                icon_clicked_priority: menuItem.quickLink.priority,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.quick-links {
    margin: 0;
    padding: 0;

    .nav {
        margin: 0;
        padding: 16px 8px;
        text-align: center;
        list-style: none;
        display: flex;
        justify-content: space-around;

        &-small {
            padding: 14px 8px;
        }
    }

    &-menu-item {
        position: relative;
        margin: 0;
        padding: 0;
        display: inline-block;
        text-align: center;
        flex-grow: 1;

        &:last-child:after {
            display: none;
        }

        &:after {
            display: block;
            content: '';
            width: 1px;
            height: 40px;
            position: absolute;
            right: -1px;
            top: 5px;
            background-color: #8e9398;
        }
    }

    &-icon {
        width: 46px;
        height: 28px;
        margin-bottom: 8px;
        fill: $top-menu-icon-color;
    }

    &-menu-text {
        color: $top-menu-icon-color;
        line-height: 14px;
        text-transform: uppercase;
        font-size: 12px;
    }

    &-menu-link {
        &:hover,
        &:focus {
            .quick-links-icon {
                fill: $menu-item-color-selected;
            }

            .quick-links-menu-text {
                color: $menu-item-color-selected;
            }
        }
    }
}
</style>
