<template>
    <div class="casino-item-wrapper">
        <CasinoCollectionGameTileModals
            :game="game"
            :modal-type="modalType"
            :modal-name="modalName"
            @closeModal="closeModal"
            @removeFavoriteCasinoGames="removeFavoriteCasinoGames"
        />
        <div :key="game.id" class="casino-item">
            <CasinoGameRibbon :game="game" @onOpenCasinoGame="openCasinoGame" />
            <div v-if="!game.isFake && isAuthenticated" class="icon-star-wrapper" @click="addFavoriteCasinoGames">
                <SvgIcon :icon-id="starIconId" class="icon-star icon-size-small" />
            </div>
            <div :class="['casino-img-wrapper', { 'is-real': !game.isFake }]" @click="openCasinoGame">
                <div v-if="isGameWithImage && isGameInViewport" :class="['casino-img-container', { 'is-hidden': !imageLoaded }]">
                    <ResponsiveImage
                        class="casino-img card-item-image"
                        :images="images"
                        @error="imageError = true"
                        @load="imageLoaded = true"
                    />
                </div>
                <div
                    v-intersection-observer="{ callback: onGameVisible, once: true }"
                    :class="['casino-img-error card-item-image card-item-error', { 'is-hidden': imageLoaded }]"
                >
                    <SvgIcon class="icon-size-very-large pointer" icon-id="icon-casino" />
                    <div class="casino-img-error-title">{{ game.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { routeName } from '@/router/const-name';
import { mapActions, mapGetters } from 'vuex';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { gameCategory } from '@/js/casino-const';
import {
    GAME_DEPOSIT_MODAL_NAME,
    GAME_FAVOURITES_MODAL_NAME,
    GAME_LAUNCHER_MODAL_NAME,
    REMOVE_GAME_FAVOURITES_MODAL_NAME,
} from '@/modules/casino/utils/CasinoCollection-const';
import CasinoCollectionGameTileModals from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTileModals.vue';
import { getObjectField } from '@/modules/core/utils/helper';
import CasinoGameRibbon from '@/modules/casino/components/CasinoGameRibbon.vue';
import { getter as casinoGetter, action as casinoAction } from '@/store/modules/casino/const';

const ResponsiveImage = () => import('@/components/ResponsiveImage.vue');

export default {
    name: 'CasinoCollectionGameTile',
    components: { CasinoGameRibbon, ResponsiveImage, CasinoCollectionGameTileModals },
    props: {
        game: Object,
    },
    data() {
        return {
            imageError: false,
            imageLoaded: false,
            modalName: null,
            modalType: null,
            isGameInViewport: false,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            favoriteGamesIds: casinoGetter.GET_FAVORITE_GAMES_IDS,
        }),
        isFavouriteGame() {
            return this.favoriteGamesIds.includes(this.game.id);
        },
        starIconId() {
            return this.isFavouriteGame ? 'icon-star-active' : 'icon-star';
        },
        isGameWithImage() {
            return !this.imageError && this.images.length > 0;
        },
        images() {
            if (this.game.isFake) return [];
            const imageSizesObj = { SMALL: 100, MEDIUM: 125, LARGE: 200, null: 100 };
            const imageSizesValue = Object.values(imageSizesObj);
            const maxNumberOfImages = imageSizesValue.length;

            const imagesArr = getObjectField(this.game, 'images', []);
            const gameThumbsSet = imagesArr.length > 0 ? imagesArr : this.game.thumbUrl;

            if (gameThumbsSet.length === 0) return [];

            return gameThumbsSet
                .slice(0, maxNumberOfImages)
                .map((image, index) => ({
                    url: image.url || image,
                    width: imagesArr.length ? imageSizesObj[image.size] : imageSizesValue[index],
                }))
                .sort((a, b) => a.width - b.width);
        },
        ribbon() {
            if (!getObjectField(this.game.ribbon, 'data')) {
                return null;
            }
            const { id, attributes } = this.game.ribbon.data;
            const { textColor, backgroundColor, name } = attributes;
            return {
                textColor,
                backgroundColor,
                name,
                id,
            };
        },
    },
    beforeDestroy() {
        this.closeModal();
    },
    methods: {
        ...mapActions({
            addFavorite: casinoAction.ADD_FAVORITE_GAME,
        }),
        openCasinoGame() {
            if (this.game.isFake) {
                return;
            }

            if (!this.isAuthenticated) {
                this.openModal(GAME_LAUNCHER_MODAL_NAME, this.game.attributes);
                return;
            }
            const query = this.$router.currentRoute.query;
            this.$router.push({
                name: routeName.CASINO,
                query: { ...query, gameId: `${this.game.id}` },
            });
        },
        addFavoriteCasinoGames() {
            const { id } = this.game;

            if (this.isAuthenticated) {
                const newFavorites = [...this.favoriteGamesIds];
                if (this.isFavouriteGame) {
                    this.openModal(REMOVE_GAME_FAVOURITES_MODAL_NAME, {
                        name: this.$t('ui.casinoLobby.removeFavouritesModal.title'),
                    });
                } else {
                    newFavorites.push(id);
                    this.$gtm.query({
                        event: 'casino_lobby_add_favourites_button_click',
                        jurisdiction: this.country,
                        isAuthenticated: this.isAuthenticated,
                    });
                    this.addFavorite(newFavorites);
                }
            } else {
                this.favouriteCandidateGameId = id;
                this.openModal(GAME_FAVOURITES_MODAL_NAME, { name: this.$t('ui.casinoLobby.favouritesModal.title') });
            }
        },
        removeFavoriteCasinoGames() {
            const game = this.game;
            if (this.isFavouriteGame) {
                const newFavorites = this.favoriteGamesIds.filter((favId) => favId !== game.id);
                this.$gtm.query({
                    event: 'casino_lobby_remove_favourites_button_click',
                    jurisdiction: this.country,
                    isAuthenticated: this.isAuthenticated,
                });
                this.addFavorite(newFavorites);
            }
        },
        closeModal() {
            this.$modal.hide(`casino-modal-${this.game.id}`);
            this.modalType = this.modalName = null;
        },
        openModal(modalName, { name, lobbyName } = {}) {
            if (name) {
                this.modalName = lobbyName || name;
            }
            switch (modalName) {
                case GAME_FAVOURITES_MODAL_NAME:
                    this.modalType = GAME_FAVOURITES_MODAL_NAME;
                    this.modalName =
                        this.currentTab.key === gameCategory.FAVOURITES
                            ? this.$t('ui.casinoLobby.favouritesModal.title')
                            : this.$t('ui.casinoLobby.notFavouritesTabModal.title');
                    break;
                case GAME_LAUNCHER_MODAL_NAME:
                    this.modalType = GAME_LAUNCHER_MODAL_NAME;
                    break;
                case REMOVE_GAME_FAVOURITES_MODAL_NAME:
                    this.modalType = REMOVE_GAME_FAVOURITES_MODAL_NAME;
                    break;
                default:
                    this.modalType = GAME_DEPOSIT_MODAL_NAME;
                    break;
            }

            this.$nextTick(() => {
                this.$modal.show(`casino-modal-${this.game.id}`);
            });
        },
        onGameVisible() {
            this.isGameInViewport = true;
        },
    },
};
</script>

<style scoped lang="scss">
.casino-item {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: 0.75px $medium-grey;
    border-radius: 8px;

    position: absolute;
    top: 0;

    &-wrapper {
        padding-top: 100%; // Alternative to aspect-ratio: 1
        height: 0;
        position: relative;
        overflow: hidden;
    }

    &:hover {
        color: $primary-color;
    }

    .casino-img-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &.is-real:hover:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(224.07deg, rgba(0, 0, 0, 0.6) 3.22%, rgba(0, 0, 0, 0) 81.24%);
            z-index: 1;
            border-radius: 8px;
        }

        .casino-img-container {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            &.is-hidden {
                visibility: hidden;
                position: absolute;
            }
            img {
                width: inherit;
                height: inherit;
                border-radius: 8px;
                object-fit: cover;
            }
        }
    }

    .casino-img-error {
        background: $light-grey-bg;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &.is-hidden {
            visibility: hidden;
            position: absolute;
        }

        svg {
            fill: #aaaeb0;
            height: 24px;
            width: 24px;
        }

        .casino-img-error-title {
            @extend %link-small-font;
            line-height: 12px;
            text-align: center;
            padding: 8px 4px 0 4px;
        }
    }

    .icon-star-wrapper {
        position: absolute;
        z-index: 2;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        padding: 4px;

        &:before {
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            background: linear-gradient(225deg, rgba(33, 34, 35, 0.63) 0%, rgba(0, 0, 0, 0) 50%);
            z-index: 1;
            border-radius: 0 8px 0 0;
        }

        .icon-star {
            fill: #f4f5f0;
            z-index: 2;
        }
    }
}
</style>
