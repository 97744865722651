var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"placed-betslip",attrs:{"data-test-class":"betSlipStatus"}},[_c('div',{staticClass:"placed-betslip-content"},[_c('SvgIcon',{staticClass:"check-icon",attrs:{"icon-id":"icon-circle-checked"}}),_c('h2',{staticClass:"content-title"},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.betPlacedTitle'))+" ")]),_c('div',{staticClass:"content-bet-placed-text"},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.betPlaced'))+" ")]),_c('div',{staticClass:"open-details-container"},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.betslip.betPlacedLinkOpen'))+" ")]),_c('router-link',{staticClass:"underline open-details-container-link",attrs:{"to":{
                    name: _vm.routeName.BETSLIP,
                    params: { id: _vm.actualBetslipId, betslipType: _vm.currentBetslipType },
                }},nativeOn:{"click":function($event){return _vm.openPlacedBetslip.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.betPlacedLink'))+" ")])],1),(_vm.sportProgressiveJackpot && !_vm.isVirtualBetslip)?_c('div',{staticClass:"pjp-message"},[_c('span',{staticClass:"pjp-message-icon"},[_c('SvgIcon',{staticClass:"icon-size-medium",attrs:{"icon-id":"message-operational","vertical-align":"text-top"}})],1),_c('renderer',{attrs:{"input":_vm.$t('ui.pjp.placedBetslipMessage')}}),_c('router-link',{staticClass:"underline pjp-message-link",attrs:{"to":_vm.progressiveJackpotLink}},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.readMore'))+" ")])],1):_vm._e(),_c('button',{staticClass:"button button-primary another-bet-button",attrs:{"data-test-class":"placeAnotherBet"},on:{"click":_vm.placeAnotherBet}},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.placeAnotherBet'))+" ")]),(!_vm.isVirtualBetslip)?_c('a',{staticClass:"underline reuse-selection-link",on:{"click":_vm.resetSlipStatus}},[_vm._v(" "+_vm._s(_vm.$t('ui.betslip.reUseSelections'))+" ")]):_vm._e(),(_vm.isAutoCashOutLinkVisible)?_c('div',{staticClass:"auto-cashout-link"},[_c('Badge',{attrs:{"text":_vm.$t('ui.common.new'),"type":"new"}}),_c('router-link',{class:['underline', _vm.isAnyBetNonCashOuTable ? 'disabled-link' : ''],attrs:{"to":{
                    name: _vm.routeName.BETSLIP,
                    params: { id: _vm.actualBetslipId, betslipType: _vm.currentBetslipType },
                    query: { activeTab: 'auto-cashout' },
                }},nativeOn:{"click":function($event){return _vm.trackAutoCashOutClick.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ui.cashout.autoCashout')))])])],1):_vm._e()],1),(_vm.isShareButtonAvailable)?_c('div',{staticClass:"placed-betslip-footer"},[_c('button',{staticClass:"button button-accent row-cell align-middle",attrs:{"disabled":_vm.sharingLoading},on:{"click":_vm.openBetSharingModal}},[_vm._v(" "+_vm._s(_vm.sharingLoading ? _vm.$t('ui.common.loading') : _vm.$t('ui.betslip.shareYourBet'))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }