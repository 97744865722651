<template>
    <div class="casino-game no-margins">
        <CasinoPills />
        <ErrorPage
            v-if="gamesLoadError || gameLaunchError"
            :title="$t('ui.casinoLobby.gameLaunchError.title')"
            :section-list="[$t('ui.casinoLobby.gameLaunchError.text')]"
            :img-src="gameLaunchErrorImg"
            :button="{ text: $t('ui.casinoLobby.gameLaunchError.button'), emit: true }"
            @button:click="errorButtonClick"
        />
        <div v-else class="loading-element">
            <LoadingElement :text="$t('ui.casinoLobby.loadingGame')" />
        </div>
        <CasinoCollectionGameTileModals v-if="game" :game="game" :modal-type="modalType" :modal-name="modalName" @closeModal="closeModal" />
    </div>
</template>

<script>
import {
    CasinoFilter,
    GAME_DEPOSIT_MODAL_NAME,
    GAME_FAVOURITES_MODAL_NAME,
    GAME_LAUNCHER_MODAL_NAME,
    REMOVE_GAME_FAVOURITES_MODAL_NAME,
} from '@/modules/casino/utils/CasinoCollection-const';
import { mapGetters, mapState } from 'vuex';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import CasinoCollectionGameTileModals from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTileModals.vue';
import CasinoPills from '@/components/content/CasinoPills.vue';
import LoadingElement from '@/components/Fragments/LoadingElement.vue';
import { getObjectField } from '@/modules/core/utils/helper';
import { action as casinoAction, getter as casinoGetter } from '@/store/modules/casino/const';
import ModalPlugin from '@/js/plugins/modal';
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';
import { getter as coreGetter } from '@/modules/core/store/const';

export default {
    name: 'CasinoGameDetails',
    components: { ErrorPage, LoadingElement, CasinoPills, CasinoCollectionGameTileModals },
    data() {
        return {
            errorModalType: false,
            modalName: null,
            modalType: null,
            gameLaunchErrorImg: ERROR_IMAGES.errorCasinoGameLaunch,
        };
    },
    computed: {
        ...mapState({
            gamesLoadError: (state) => state.casino.games.error,
            gameLaunchError: (state) => state.casino.gameLaunchError,
            isBalanceReady: (state) => state.platform.auth.isBalanceReady,
        }),
        ...mapGetters({
            settings: platformGetter.GET_SETTINGS,
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            balance: authGetter.GET_BALANCE,
            chipsBalance: authGetter.GET_CHIPS_BALANCE,
            casinoName: translationsGetter.CASINO_NAME,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            country: platformGetter.GET_COUNTRY,
            games: casinoGetter.GET_GAMES,
            isLoading: coreGetter.IS_LOADING,
        }),
        chips() {
            return this.settings.chips;
        },
        game() {
            const toOpenGameId = this.$router.currentRoute.query[CasinoFilter.GAME_ID];
            return getObjectField(this.games, 'data', []).find(
                ({ id, gameId }) => id === Number(toOpenGameId) || String(gameId).toLowerCase() === String(toOpenGameId).toLowerCase()
            );
        },
    },
    watch: {
        games: {
            immediate: true,
            handler() {
                if (!this.games.data) return;
                this.gameLauncher();
            },
        },
        balance(value) {
            if (value !== null) {
                this.gameLauncher();
            }
        },
        chipsBalance(value) {
            if (value !== null) {
                this.gameLauncher();
            }
        },
    },
    mounted() {
        this.$scroll.scrollTo(0, 1);
        if (!this.isLoading(casinoAction.GET_CASINO_GAMES_NEW)) {
            this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        }
        this.$gtm.query({ event: 'casino_game_page_view' });
        ModalPlugin.event.$on('hide', this.onChipsModalClose);
    },
    beforeDestroy() {
        ModalPlugin.event.$off('hide', this.onChipsModalClose);
    },
    methods: {
        gameLauncher() {
            if (!this.game) {
                return;
            }
            this.errorModalType = GAME_LAUNCHER_MODAL_NAME;
            const { name, provider, providerGameId } = this.game;
            const hasNoBetpawaChips = this.isAuthenticated && this.chips && this.chipsBalance < this.settings.betting.real.minStake;
            const hasNoBalance = this.isAuthenticated && !this.chips && this.balance <= 0;
            if (hasNoBetpawaChips) {
                this.$modal.show('chips-modal');
                return;
            }

            if (!this.isBalanceReady && this.isAuthenticated) {
                return;
            }

            if (!this.isAuthenticated || hasNoBalance) {
                this.openModal(hasNoBalance ? GAME_DEPOSIT_MODAL_NAME : GAME_LAUNCHER_MODAL_NAME, this.game.lobbyName || this.game.name);
                return;
            }

            const redirectFn = () => {
                this.$router.push({ ...this.$route, query: { ...this.$route.query, gameId: undefined } });
            };
            const lobbyUrl = this.$route.fullPath.replace(new RegExp(`${CasinoFilter.GAME_ID}=\\d*`), '');
            this.$store.dispatch(casinoAction.OPEN_CASINO_GAME, {
                name,
                provider,
                providerGameId,
                redirectFn,
                lobbyUrl,
            });
        },
        closeModal(type) {
            this.$router.back();
            this.$modal.hide(`casino-modal-${this.game.id}`);
            this.modalType = this.modalName = null;
        },
        onChipsModalClose(name) {
            if (name === 'chips-modal') this.$router.back();
        },
        openModal(modalName, gameName) {
            this.modalName = gameName;
            switch (modalName) {
                case GAME_FAVOURITES_MODAL_NAME:
                    return; // not needed in this component for now
                case GAME_LAUNCHER_MODAL_NAME:
                    this.modalType = GAME_LAUNCHER_MODAL_NAME;
                    break;
                case REMOVE_GAME_FAVOURITES_MODAL_NAME:
                    return; // not needed in this component for now
                default:
                    this.modalType = GAME_DEPOSIT_MODAL_NAME;
                    break;
            }

            this.$nextTick(() => {
                this.$modal.show(`casino-modal-${this.game.id}`);
            });
        },
        errorButtonClick() {
            this.$router.push({ name: routeName.CASINO });
        },
    },
};
</script>

<style scoped lang="scss">
.casino-game {
    display: flex;
    flex-direction: column;
    background: $body-background-color;
    height: 100%;

    .loading-element {
        flex: 1;
    }
}
</style>
