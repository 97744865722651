<template>
    <div>
        <div
            v-for="(option, index) in options"
            :key="index"
            class="select-item"
            :class="{ 'select-item-current': option.isCurrent }"
            @click="handleSelectItemClick(option)"
        >
            <div class="label">
                <SvgIcon v-if="option.iconId" class="icon icon-size-medium" :icon-id="option.iconId" />
                {{ option.label }}
            </div>
            <div class="count">{{ option.count }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterBySport',
    props: {
        options: {
            type: Array[Object],
            required: true,
        },
    },
    methods: {
        handleSelectItemClick(item) {
            this.$emit('click', item);
        },
    },
};
</script>

<style lang="scss" scoped>
.select-item {
    padding: 11px 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend %body-normal-font-400;

    .label {
        display: flex;
        align-items: center;
    }

    & + & {
        margin-top: 4px;
    }
    &-current {
        background-color: #d9edb2;
        border-radius: 4px;
        @extend %body-normal-font-700;
    }

    .svg-icon {
        margin-right: 6px;
    }
}
</style>
