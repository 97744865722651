<template>
    <div>
        <div class="event-sorting-label">{{ $t('ui.eventSorting.sortBy') }}</div>
        <EventRadioItem
            v-for="(item, index) in options"
            :key="index"
            radio-id="options-event-sorting"
            :checked="isChecked(item)"
            :label="item.label"
            @click.native.prevent="handleRadioItemClick(item)"
        />
    </div>
</template>

<script>
import { EventRadioItem } from '@/modules/sport/components';
import { EVENTS_SORTING_ACCEPTABLE_MAP, SPORT_CATEGORY } from '@/modules/sport/const/events-const';

export default {
    name: 'EventSorting',
    components: { EventRadioItem },
    props: {
        sorting: {
            type: String,
            default: EVENTS_SORTING_ACCEPTABLE_MAP.competitionPriority_DESC,
        },
        categoryId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            options: [
                {
                    label: this.$t('ui.eventSorting.competitionPriority_DESC'),
                    sorting: EVENTS_SORTING_ACCEPTABLE_MAP.competitionPriority_DESC,
                },
                {
                    label: this.$t('ui.eventSorting.startTime_DESC'),
                    sorting: EVENTS_SORTING_ACCEPTABLE_MAP.startTime_DESC,
                },
                ...(this.categoryId === SPORT_CATEGORY.FOOTBALL
                    ? [
                          {
                              label: this.$t('ui.eventSorting.startTime_ASC'),
                              sorting: EVENTS_SORTING_ACCEPTABLE_MAP.startTime_ASC,
                          },
                      ]
                    : []),
            ],
        };
    },
    methods: {
        handleRadioItemClick(item) {
            this.$emit('click', item.sorting);
        },
        isChecked(item) {
            return this.sorting === item.sorting;
        },
    },
};
</script>

<style lang="scss" scoped>
.event-sorting-label {
    padding: 12px;
    @extend %body-normal-font-700;
    text-transform: uppercase;
}
</style>
