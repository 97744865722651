export const statementTypes = {
    GENERAL: 'general',
    BETPAWA_CHIPS: 'betPawaChips',
};

export const BETSLIP_TRANSACTION_TYPES_VIRTUAL = ['BET_SPORT_VIRTUAL', 'WIN_SPORT_VIRTUAL', 'LEG_BONUS_WIN_SPORT_VIRTUAL'];

export const BETSLIP_TRANSACTION_TYPES_REAL = [
    'BET_SPORT',
    'BONUS_BET_SPORT',
    'WIN_SPORT',
    'BONUS_WIN_SPORT',
    'BONUS_CONVERTED_SPORT_BET',
    'BONUS_CONVERTED_SPORT_BET_2',
    'BONUS_CONVERTED_SPORT_WIN',
    'BONUS_CONVERTED_SPORT_WIN_2',
    'BONUS_CONVERTED_SPORT_WAGER_WIN',
    'BONUS_CONVERTED_SPORT_WAGER_BET',
    'AGENT_DEPOSIT_TO_USER',
    'AGENT_COMMISSION',
    'LEG_BONUS_REAL',
    'STAKE_TAX_SPORT',
    'CASHOUT',
];

export const BETSLIP_TRANSACTION_TYPES = [...BETSLIP_TRANSACTION_TYPES_REAL, ...BETSLIP_TRANSACTION_TYPES_VIRTUAL];
