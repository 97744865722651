import { EventType } from '@/modules/sport';

/**
 *
 * @param {Array<string>} categories - sport categories
 * @param {('UPCOMING' | 'LIVE')} [eventType] - types of events
 * @param {Array<string>} [regions] - region ids
 * @param {Array<string>} [competitions] - competition ids
 * @param {Array<string>} [marketTypes] - market type ids
 * @param {number} take - how many events to get
 * @param {number} [page] - pagination index
 * @param {boolean} [onlyPopular] - include only popular events
 * @param {boolean} [onlyBoosted] - include only boosted events
 * @param {boolean} [hasOdds] -Check that event has any price
 * @param {{ byField: 'ASC' }} [sort] -Check that event has any price
 */
export function buildEventsQueryParams({
    categories,
    eventType,
    regions,
    competitions,
    marketTypes,
    take,
    page,
    onlyPopular,
    onlyBoosted,
    hasOdds,
    sort,
}) {
    const queryMarketTypes = !hasOdds && eventType === EventType.UPCOMING ? marketTypes : undefined;
    const viewParams = marketTypes ? { marketTypes } : undefined;
    const skipValue = (page ?? 0) * take;

    return {
        query: {
            eventType,
            categories,
            zones: {
                regions: getArrayValueOrUndefined(regions),
                competitions: getArrayValueOrUndefined(competitions),
            },
            marketTypes: queryMarketTypes,
            popular: onlyPopular,
            boosted: onlyBoosted,
            hasOdds,
        },
        view: viewParams,
        skip: skipValue,
        ...(sort
            ? {
                  sort,
              }
            : {}),
        take,
    };
}

const getArrayValueOrUndefined = (value) => {
    return Array.isArray(value) && value.length ? value : undefined;
};
