export const action = {
    GET_BALANCE: 'platform/auth/getBalance',
    GET_CHIPS_BALANCE: 'platform/auth/getChipsBalance',
    LOGIN: 'platform/auth/login',
    LOGOUT: 'platform/auth/logout',
    REGISTER: 'platform/auth/register',
    VERIFY_ACCOUNT_BY_HASH: 'platform/auth/verifyAccountByHash',
    RESET_PASSWORD: 'platform/auth/resetPassword',
    SET_PASSWORD: 'platform/auth/setPassword',
    UPDATE_PASSWORD_BY_OTP: 'platform/auth/updatePasswordByOTP',
    UPDATE_OTP_ATTEMPTS: 'platform/auth/updateOtpAttempts',
    SET_ERROR: 'platform/auth/setError',
    RESET_ERROR: 'platform/auth/resetError',
    CHANGE_PASSWORD: 'platform/auth/changePassword',
    RESET_USER: 'platform/auth/resetAuth',
    VERIFY_KYC: 'platform/auth/kycVerification',
    ENABLE_ACCESS: 'platform/auth/enableAccess',
    SELF_EXCLUDE: 'platform/auth/selfExclude',
    GENERATE_RESET_PASSWORD_TRACKING_ID: 'platform/auth/generateResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
};

export const mutation = {
    SET_ERROR: 'platform/auth/setError',
    RESET_ERROR: 'platform/auth/resetError',
    SET_PHONE_NUMBER: 'platform/auth/setPhoneNumber',
    SET_AUTH: 'platform/auth/setAuth',
    SET_OTP_TIMER: 'platform/auth/setOtpTimer',
    RESET_AUTH: 'platform/auth/resetAuth',
    SET_SECURITY_TOKEN: 'platform/auth/setSecurityToken',
    SET_CAPTCHA_ERROR: 'platform/auth/setCaptchaError',
    RESET_CAPTCHA_ERROR: 'platform/auth/resetCaptchaError',
    SET_OTP_ATTEMPTS: 'platform/auth/incrementOtpAttempts',
    RESET_OTP_ATTEMPTS: 'platform/auth/resetOtpAttempts',
    SET_OTP_LIMITS: 'platform/auth/setOtpLimits',
    SET_RESET_PASSWORD_TRACKING_ID: 'platform/auth/setResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
    SET_SESSION_EXPIRED: 'platform/auth/setSessionExpired',
};

export const getter = {
    SECURED_TOKEN: 'platform/auth/securedToken',
    IS_CAPTCHA_ENABLED: 'platform/auth/isCaptchaEnabled',
    IS_AUTHENTICATED: 'platform/auth/isAuthenticated',
    IS_USER_VERIFIED: 'platform/auth/isUserVerified',
    GET_BALANCE: 'platform/auth/getBalance',
    GET_CHIPS_BALANCE: 'platform/auth/getChipsBalance',
    GET_OTP_TIMER: 'platform/auth/getOtpTimer',
    GET_CAPTCHA_ERROR: 'platform/auth/getCaptchaError',
    GET_AUTH_ERROR: 'platform/auth/getAuthError',
    GET_OTP_ATTEMPTS_MAX_COUNT: 'platform/auth/getOtpAttemptsMaxCount',
    GET_OTP_COUNTDOWN_SECONDS: 'platform/auth/getOtpCountdownSeconds',
    IS_OTP_ATTEMPTS_LIMITED: 'platform/auth/isOtpAttemptsLimited',
    GET_OTP_LIMITS: 'platform/auth/getOtpLimits',
    GET_STORED_PHONE_NUMBER: 'platform/auth/getStoredPhoneNumber',
    GET_RESET_PASSWORD_TRACKING_ID: 'platform/auth/getResetPasswordTrackingId',
    NEED_UPDATE_SECURED_TOKEN: 'platform/auth/needUpdateSecuredToken',
    IS_SESSION_EXPIRED: 'platform/auth/isSessionExpired',
};
