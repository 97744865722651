<template>
    <Modal
        name="terms-and-conditions"
        class="terms-and-conditions"
        width="336px"
        is-highest
        is-scrollable
        :is-empty-header="isTermsDeclined"
        :options="{
            overlay: true,
        }"
        :click-outside="false"
        :fixed="true"
        @close="onClose"
    >
        <template #default>
            <div v-if="isTermsDeclined" class="reject-terms-and-conditions">
                <p class="title upperCase">{{ $t('ui.termsAndConditions.rejectTitle') }}</p>
                <p class="description">
                    {{
                        $t('ui.termsAndConditions.rejectDescription', { oldLicenseCompany: paymentProviderMigrationData.oldLicenseCompany })
                    }}
                </p>
                <div class="footer">
                    <button class="button button-primary button-full" @click="goBack">
                        {{ $t('ui.termsAndConditions.goBack') }}
                    </button>
                    <button class="button button-accent button-full" @click="logOut">
                        {{ $t('ui.termsAndConditions.yes') }}
                    </button>
                </div>
            </div>
            <div v-else>
                <p class="title upperCase">{{ $t('ui.termsAndConditions.title') }}</p>
                <p class="description">
                    {{
                        $t('ui.termsAndConditions.description', {
                            oldLicenseCompany: paymentProviderMigrationData.oldLicenseCompany,
                            licenseCompany: paymentProviderMigrationData.licenseCompany,
                        })
                    }}
                </p>
                <div class="introduction">
                    <template v-if="terms && terms.content">
                        <DynamicComponent
                            v-for="(item, index) in termsContent"
                            :key="`${item.type}-${index}`"
                            :data="item"
                            path="content"
                            :data-test-id="`tag-${item.type}`"
                            data-test-class="cmsTag"
                        />
                    </template>
                </div>
                <template v-if="isPresto && terms && terms.content">
                    <div class="terms-pagination-wrapper">
                        <span v-if="termsSliceIndex > 0" @click="termsSliceIndex--">
                            {{ $t('ui.termsAndConditions.previousPage') }}
                        </span>
                        <span v-if="termsSliceIndex < maxTermsSliceIndex" @click="termsSliceIndex++">
                            {{ $t('ui.termsAndConditions.nextPage') }}
                        </span>
                    </div>
                </template>
                <button v-if="isPresto" class="accept-button button button-primary button-full" @click="onAccept">
                    {{ $t('ui.termsAndConditions.accept') }}
                </button>
            </div>
        </template>
        <template v-if="!isTermsDeclined && !isPresto" #footer>
            <button class="button button-primary button-full" @click="onAccept">
                {{ $t('ui.termsAndConditions.accept') }}
            </button>
        </template>
    </Modal>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { action, mutation } from '@/store/const';
import { getter as platformGetter, action as platformAction } from '@/modules/platform/store/const';
import { action as authAction } from '@/modules/platform/store/modules/auth/const';

import { DynamicComponent } from '@/modules/platform/components';
import { routeName } from '@/router/const-name';
import { getter as paymentGetter } from '@/modules/payment/store/const';
import { deviceType } from '@/modules/core';

const PAGE_NAME_TERMS = 'terms';
const TERMS_SLICE_SIZE = 50;

export default {
    name: 'TermsAndConditions',
    components: {
        DynamicComponent,
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            termsSliceIndex: 0,
        };
    },
    computed: {
        ...mapState({
            isTermsDeclined: (state) => state.isTermsDeclined,
        }),
        ...mapGetters({
            getContentGetter: platformGetter.GET_CONTENT_SLOTS,
            getPages: platformGetter.GET_PAGES,
            paymentProviderMigrationData: paymentGetter.GET_PAYMENT_PROVIDER_MIGRATION_DATA,
        }),
        terms() {
            return this.getPages['terms'];
        },
        termsContent() {
            if (!this.isPresto) {
                return this.terms.content;
            }

            const sliceStart = this.termsSliceIndex * TERMS_SLICE_SIZE;
            const sliceEnd = (this.termsSliceIndex + 1) * TERMS_SLICE_SIZE;

            return this.terms.content.slice(sliceStart, sliceEnd);
        },
        maxTermsSliceIndex() {
            return Math.floor(this.terms.content.length / TERMS_SLICE_SIZE);
        },
    },
    created() {
        this.$store.dispatch(platformAction.GET_PAGE, PAGE_NAME_TERMS);
    },
    mounted() {
        this.$modal.show('terms-and-conditions');
    },
    methods: {
        ...mapActions({
            acceptTerms: action.ACCEPT_TERMS,
        }),
        ...mapMutations({
            updateTermsDeclineStatus: mutation.UPDATE_TERMS_DECLINE_STATUS,
        }),
        onAccept() {
            const value = { key: 'terms_accepted_date', value: new Date().toISOString() };
            this.acceptTerms(value);
            if (this.$route.name === routeName.HOMEPAGE) {
                this.$store.dispatch('Notify', {
                    type: 'terms',
                    data: {
                        trigger: true,
                    },
                });
            } else {
                this.$router.push({ name: routeName.HOMEPAGE }).then(() => {
                    this.$store.dispatch('Notify', {
                        type: 'terms',
                        data: {
                            trigger: true,
                        },
                    });
                });
            }
        },
        onClose() {
            this.updateTermsDeclineStatus(true);
        },
        goBack() {
            this.updateTermsDeclineStatus(false);
        },
        logOut() {
            this.$store.dispatch(authAction.LOGOUT).then(() => {
                this.goBack();
                this.$router.push({ name: routeName.HOMEPAGE });
            });
        },
    },
};
</script>

<style scoped lang="scss">
.terms-and-conditions {
    color: $main-text;

    ::v-deep {
        .modal-wrapper {
            max-height: 80vh;
        }
        .modal-container {
            max-height: 80vh;
        }
    }
}
.title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 8px;
}
.description {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
}
.introduction {
    padding: 0 16px 16px;
    background-color: $light-grey;
}

.reject-terms-and-conditions {
    button {
        margin-top: 10px;
    }
}

.accept-button {
    margin-top: 15px;
}

.terms-pagination-wrapper {
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
}
</style>
